
export const SET_CURRENT_JACKPOT_LEVELS = 'SET_CURRENT_JACKPOT_LEVELS';

const setCurrentJackpotLevelsAction = (data) => ({
    type: SET_CURRENT_JACKPOT_LEVELS,
    payload: data
});

export { 
    setCurrentJackpotLevelsAction
};