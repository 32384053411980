
export const SET_ANONYMOUS_GAMES_FOR_DESKTOP = 'SET_ANONYMOUS_GAMES_FOR_DESKTOP';

const setAnonymousGamesForDesktopAction = (data) => ({
    type: SET_ANONYMOUS_GAMES_FOR_DESKTOP,
    payload: data
});

export { 
    setAnonymousGamesForDesktopAction
};