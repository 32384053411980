import {
    SET_TID_FOR_REGISTRATION,
} from "../../actions/tidForRegistration/TidForRegistrationActions"

const initialState = {
    tidForRegistration: ""
}

let TidForRegistrationReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_TID_FOR_REGISTRATION: 
            return {
                ...state, 
                tidForRegistration: action.payload,
            };
        default: 
            return state;    
    }
};

export { TidForRegistrationReducer };