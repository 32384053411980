
export const SET_PROMOS_AND_BONUSES = 'SET_PROMOS_AND_BONUSES';

export const DELETE_PROMOS_AND_BONUSES = 'DELETE_PROMOS_AND_BONUSES';

const setPromosAndBonusesAction = (data) => ({
    type: SET_PROMOS_AND_BONUSES,
    payload: data
});

const deletePromosAndBonusesAction = (data) => ({
    type: DELETE_PROMOS_AND_BONUSES,
    payload: {}
})

export { 
    setPromosAndBonusesAction,
    deletePromosAndBonusesAction
};