
export const CHANGE_PLAYER_VERIFICATION_STATUS = 'CHANGE_PLAYER_VERIFICATION_STATUS';

export const DELETE_PLAYER_VERIFICATION_STATUS = 'DELETE_PLAYER_VERIFICATION_STATUS';

const changePlayerVerificationStatusAction = (data) => ({
    type: CHANGE_PLAYER_VERIFICATION_STATUS,
    payload: data
});

const deletePlayerVerificationStatusAction = (data) => ({
    type: DELETE_PLAYER_VERIFICATION_STATUS,
    payload: data
});


export {
    changePlayerVerificationStatusAction,
    deletePlayerVerificationStatusAction
};