import {
    DELETE_PROMOS_AND_BONUSES,
    SET_PROMOS_AND_BONUSES
} from "../../actions/listPromosAndBonuses/ListPromosAndBonusesAction"

const initialState = {
    listPromosAndBonuses: {}
}

let ListPromosAndBonusesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PROMOS_AND_BONUSES: 
            return {
                ...state, 
                listPromosAndBonuses: action.payload,
            };
        case DELETE_PROMOS_AND_BONUSES:
            return {
                initialState
            };
        default: 
            return state;    
    }
};

export { ListPromosAndBonusesReducer };