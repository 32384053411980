import {
    SET_PLAY_GAME_DIALOG_STATUS,
    DELETE_PLAY_GAME_DIALOG_STATUS
} from "../../actions/playGameDialog/PlayGameDialogActions"

const initialState = {
    playGameDialogStatus: {}
}

let PlayGameDialogStatusReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PLAY_GAME_DIALOG_STATUS: 
            return {
                ...state, 
                playGameDialogStatus: action.payload,
            };
        case DELETE_PLAY_GAME_DIALOG_STATUS: 
            return {
                initialState
            };     
        default: 
            return state;    
    }
};

export { PlayGameDialogStatusReducer };