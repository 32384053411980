import {
    SET_REGISTRATION_CODE_FOR_REGISTRATION,
} from "../../actions/registrationCodeForRegistration/RegistrationCodeForRegistrationActions"

const initialState = {
    registrationCodeForRegistration: ""
}

let RegistrationCodeForRegistrationReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_REGISTRATION_CODE_FOR_REGISTRATION: 
            return {
                ...state, 
                registrationCodeForRegistration: action.payload,
            };
        default: 
            return state;    
    }
};

export { RegistrationCodeForRegistrationReducer };