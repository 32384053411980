import {
    GET_PLAYER_DETAILS, SAVE_PLAYER_DETAILS
} from "../../actions/playerDetails/PlayerDetailsActions"

const initialState = {
    playerDetails: {}
}

let PlayerDetailsReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_PLAYER_DETAILS: 
            return {
                ...state, 
                playerDetails: action.payload,
            };
        case SAVE_PLAYER_DETAILS:
            return {
                ...state,
                playerDetails: action.payload
            }
        default: 
            return state;
    }
};

export { PlayerDetailsReducer };