
export const SET_FORGOT_USERNAME_DIALOG_STATUS = 'SET_FORGOT_USERNAME_DIALOG_STATUS';

export const DELETE_FORGOT_USERNAME_DIALOG_STATUS = 'DELETE_FORGOT_USERNAME_DIALOG_STATUS';

const setForgotUsernameDialogStatusAction = (data) => ({
    type: SET_FORGOT_USERNAME_DIALOG_STATUS,
    payload: data
});

const deleteForgotUsernameDialogStatusAction = () => ({
    type: DELETE_FORGOT_USERNAME_DIALOG_STATUS,
    payload: {}
});

export { 
    setForgotUsernameDialogStatusAction,
    deleteForgotUsernameDialogStatusAction
};