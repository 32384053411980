
export const SET_TARGET_PAGE_AFTER_LOGIN = 'SET_TARGET_PAGE_AFTER_LOGIN';
export const CLEAR_TARGET_PAGE_AFTER_LOGIN = 'CLEAR_TARGET_PAGE_AFTER_LOGIN';

const setTargetPageAfterLoginAction = (data) => ({
    type: SET_TARGET_PAGE_AFTER_LOGIN,
    payload: data
});

const clearTargetPageAfterLoginAction = () => ({
    type: CLEAR_TARGET_PAGE_AFTER_LOGIN,
    payload: ""
})

export { 
    setTargetPageAfterLoginAction,
    clearTargetPageAfterLoginAction
};