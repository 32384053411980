
export const SET_REGISTRATION_DIALOG_STATUS = 'SET_REGISTRATION_DIALOG_STATUS';

export const DELETE_REGISTRATION_DIALOG_STATUS = 'DELETE_REGISTRATION_DIALOG_STATUS';

const setRegistrationDialogStatusAction = (data) => ({
    type: SET_REGISTRATION_DIALOG_STATUS,
    payload: data
});

const deleteRegistrationDialogStatusAction = () => ({
    type: DELETE_REGISTRATION_DIALOG_STATUS,
    payload: {}
});

export { 
    setRegistrationDialogStatusAction,
    deleteRegistrationDialogStatusAction
};