import * as config from '../../configuration/Config';
import CryptoJS from 'crypto-js';
import moment from 'moment';

const loginPlayerService = (loginData) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'site-login',
        site_name: config.affiliateName,
        username: loginData.username,
        password: loginData.password,
        mac_address: '',
        version: '',
        ip_address: loginData.ip_address,
        country: '',
        city: '',
        device_aff_id: '',
        gp_mac_address: '',
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'site-login',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if(config.USE_JWT_IN_RESPONSE)
    {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }
 
    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
    .then((response) => {
        return response.json();
    })
    .catch((error) => {
        //console.error(error);
        return {
            status: 'NOK',
            message: 'Error in server request'
        };
    });
};

const logoutPlayerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');
    
    let payload = {
        operation: 'site-logout',
        site_session_id: data.site_session_id,
        pc_session_id: data.pc_session_id,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'site-logout',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if(config.USE_JWT_IN_RESPONSE)
    {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }
 
    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
    .then((response) => {
        return response.json();
    })
    .catch((error) => {
        //console.error(error);
        return {
            status: 'NOK',
            message: 'Error in server request'
        };
    });
};

const setTimeModifiedService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-time-modified',
        site_session_id: data.site_session_id,
        pc_session_id: data.pc_session_id,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-time-modified',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if(config.USE_JWT_IN_RESPONSE)
    {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
    .then((response) => {
        return response.json();
    })
    .catch((error) => {
        //console.error(error);
        return {
            status: 'NOK',
            message: 'Error in server request'
        };
    });
};

const openAnonymousSessionService = () => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');
    
    let payload = {
        operation: 'open-anonymous-session',        
        ip_address: '',
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'open-anonymous-session',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if(config.USE_JWT_IN_RESPONSE)
    {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }
 
    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
    .then((response) => {
        return response.json();
    })
    .catch((error) => {
        //console.error(error);
        return {
            status: 'NOK',
            message: 'Error in server request'
        };
    });
};

const validateSiteSessionService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'validate-site-session',
        site_session_id: data.site_session_id,
        pc_session_id: data.pc_session_id,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'validate-site-session',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if(config.USE_JWT_IN_RESPONSE)
    {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    //console.log(payload);

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
    .then((response) => {
        //console.log(response);
        return response.json();
    })
    .then((json) => {
        //console.log(json);
        return json;
    })
    .catch((error) => {
        //console.log(error);
        return {
            status: 'NOK',
            message: 'Error in server request'
        };
    });
};

export { 
    loginPlayerService,
    logoutPlayerService,
    setTimeModifiedService,
    openAnonymousSessionService,
    validateSiteSessionService
};