import {
    ADD_FAVOURITE_GAME,
    REMOVE_FAVOURITE_GAME
} from "../../actions/listFavouriteGames/ListFavouriteGamesAction"

const initialState = {
    listFavouriteGames: new Array()
}

let ListFavouriteGamesReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case ADD_FAVOURITE_GAME:

            state.listFavouriteGames = [...state.listFavouriteGames, action.payload];

            state.listFavouriteGames = [... new Set(state.listFavouriteGames)];
            
            return {
                ...state, 
                listFavouriteGames: state.listFavouriteGames
            };
        case REMOVE_FAVOURITE_GAME:
            if(state.listFavouriteGames.length > 0){
                state.listFavouriteGames = state.listFavouriteGames.filter(item => JSON.stringify(item) !== JSON.stringify(action.payload))
                state.listFavouriteGames = [... new Set(state.listFavouriteGames)];
            }else{
                state.listFavouriteGames = []
            }

            return {
                ...state, 
                listFavouriteGames: state.listFavouriteGames
            };
        default: 
            return state;    
    }
};

export { ListFavouriteGamesReducer };