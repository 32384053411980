import {
    SET_CURRENT_JACKPOT_LEVELS
} from "../../actions/listCurrentJackpotLevels/ListCurrentJackpotLevelsAction"

const initialState = {
    listCurrentJackpotLevels: {}
}

let ListCurrentJackpotLevelsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CURRENT_JACKPOT_LEVELS: 
            return {
                ...state, 
                listCurrentJackpotLevels: action.payload,
            };        
        default: 
            return state;    
    }
};

export { ListCurrentJackpotLevelsReducer };