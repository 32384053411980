

export const SET_REGISTRATION_CODE_FOR_REGISTRATION = 'SET_REGISTRATION_CODE_FOR_REGISTRATION';

const setRegistrationCodeForRegistrationAction = (data) => ({
    type: SET_REGISTRATION_CODE_FOR_REGISTRATION,
    payload: data
});

export { 
    setRegistrationCodeForRegistrationAction
};