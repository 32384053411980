
export const SET_ANONYMOUS_GAMES_FOR_MOBILE = 'SET_ANONYMOUS_GAMES_FOR_MOBILE';

const setAnonymousGamesForMobileAction = (data) => ({
    type: SET_ANONYMOUS_GAMES_FOR_MOBILE,
    payload: data
});

export { 
    setAnonymousGamesForMobileAction
};