import {
    SET_ANONYMOUS_GAMES_FOR_MOBILE
} from "../../actions/listAnonymousGamesForMobile/ListAnonymousGamesForMobileAction"

const initialState = {
    listAnonymousGamesForMobile: {}
}

let ListAnonymousGamesForMobileReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ANONYMOUS_GAMES_FOR_MOBILE: 
            return {
                ...state, 
                listAnonymousGamesForMobile: action.payload,
            };        
        default: 
            return state;    
    }
};

export { ListAnonymousGamesForMobileReducer };