import {
    SET_LICENCE_INFORMATION,
} from "../../actions/licenceInformation/LicenceInformationActions"

const initialState = {
    licenceInformation: {}
}

let LicenceInformationReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_LICENCE_INFORMATION: 
            return {
                ...state, 
                licenceInformation: action.payload,
            };
        default: 
            return state;    
    }
};

export { LicenceInformationReducer };