
export const SET_LOGIN_DIALOG_STATUS = 'SET_LOGIN_DIALOG_STATUS';

export const DELETE_LOGIN_DIALOG_STATUS = 'DELETE_LOGIN_DIALOG_STATUS';

const setLoginDialogStatusAction = (data) => ({
    type: SET_LOGIN_DIALOG_STATUS,
    payload: data
});

const deleteLoginDialogStatusAction = () => ({
    type: DELETE_LOGIN_DIALOG_STATUS,
    payload: {}
});

export { 
    setLoginDialogStatusAction,
    deleteLoginDialogStatusAction
};