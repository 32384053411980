import * as config from '../../configuration/Config';
import CryptoJS from 'crypto-js';
import moment from 'moment';

const getValidatePlayerUsernameService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'validate-player-username',
        username: data.username,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'validate-player-username',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const getListCountriesForRegistrationFormService = () => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'list-countries',
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'list-countries',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const getListCurrencyForRegistrationFormService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'currency-list-for-new-player',
        affiliate_id: data.affiliate_id,
        tid_code: data.tid_code,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'currency-list-for-new-player',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const getCurrencyForPromoCodeService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'currency-for-promo-code',
        affiliate_name: data.affiliate_name,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'currency-for-promo-code',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const getSecurityQuestionsService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'get-security-question',
        username: data.username,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'get-security-question',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setSecurityQuestionAnswerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-security-question-answer',
        site_session_id: data.site_session_id,
        username: data.username,
        password: data.password,
        question: data.question,
        answer: data.answer,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-security-question-answer',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const getSecurityQuestionAnswerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'get-security-question-answer',
        site_session_id: data.site_session_id,
        username: data.username,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'get-security-question-answer',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const pause = (ms, cb, ...args) =>
    new Promise((resolve, reject) => {
        setTimeout(async () => {
            try {
                resolve(await cb?.(...args))
            } catch (error) {
                reject(error)
            }
        }, ms)
    })

const registerPlayerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'insert-player',
        affiliate_id: data.affiliate_id,
        username: data.username,
        password: data.password,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        birthday: data.birthday,
        country: data.country,
        zip: data.zip,
        city: data.city,
        street_address1: data.street_address1,
        street_address2: data.street_address2,
        phone: data.phone,
        bank_account: data.bank_account,
        bank_country: data.bank_country,
        swift: data.swift,
        iban: data.iban,
        receive_email: data.receive_email,
        currency: data.currency,
        ip_address: data.ip_address,
        registration_code: data.registration_code,
        tid_code: data.tid_code,
        language: data.language,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'insert-player',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const metamaskRegisterPlayerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'metamask-register-player',
        affiliate_id: data.affiliate_id,
        username: data.username,
        password: data.password,
        //email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        birthday: data.birthday,
        country: data.country,
        zip: data.zip,
        city: data.city,
        street_address1: data.street_address1,
        street_address2: data.street_address2,
        phone: data.phone,
        bank_account: data.bank_account,
        bank_country: data.bank_country,
        swift: data.swift,
        iban: data.iban,
        receive_email: data.receive_email,
        currency: data.currency,
        ip_address: data.ip_address,
        registration_code: data.registration_code,
        tid_code: data.tid_code,
        language: data.language,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'metamask-register-player',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const updatePlayerInformationService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'update-player',
        session_id: data.site_session_id,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        birthday: data.birthday,
        country: data.country,
        zip: data.zip,
        city: data.city,
        street_address1: data.street_address1,
        street_address2: data.street_address2,
        phone_number: data.phone_number,
        bank_account: data.bank_account,
        bank_country: data.bank_country,
        swift: data.swift,
        iban: data.iban,
        receive_email: data.receive_email,
        ip_address: data.ip_address,
        language: data.language,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'update-player',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const forgotPasswordWithSecurityAnswerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'forgot-password-with-security-answer',
        username: data.username,
        answer: data.security_answer,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'forgot-password-with-security-answer',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const forgotPasswordWithUsernameService = async (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'forgot-password-with-username',
        username: data.username,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'forgot-password-with-username',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const forgotUsernameService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'forgot-username',
        name: data.first_name,
        familyname: data.last_name,
        birthday: data.birthday,
        email: data.email,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'forgot-username',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const playerDetailsService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'player-details',
        session_id: data.site_session_id,
        ip_address: data.ip_address,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'player-details',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const sendPlayerActivationMailService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'send-player-activation-mail',
        player_id: data.player_id,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'send-player-activation-mail',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        axiosConfig.headers['JWT'] = CryptoJS.SHA1(config.JWT_SECRET_KEY);
    }

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
}

const getListLimitsForPlayerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'list-limits',
        session_id: data.site_session_id,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'list-limits',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const responsibleGamingSetupForPlayerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'responsible-gaming-setup-delay',
        session_id: data.site_session_id,

        monthly_deposit_limit: data.monthly_deposit_limit,
        monthly_deposit_limit_start_date: data.monthly_deposit_limit_start_date,
        monthly_deposit_limit_end_date: data.monthly_deposit_limit_end_date,

        weekly_deposit_limit: data.weekly_deposit_limit,
        weekly_deposit_start_date: data.weekly_deposit_start_date,
        weekly_deposit_end_date: data.weekly_deposit_end_date,

        daily_deposit_limit: data.daily_deposit_limit,
        daily_deposit_start_date: data.daily_deposit_start_date,
        daily_deposit_end_date: data.daily_deposit_end_date,

        monthly_max_loss_limit: data.monthly_max_loss_limit,
        monthly_max_loss_start_date: data.monthly_max_loss_start_date,
        monthly_max_loss_end_date: data.monthly_max_loss_end_date,

        weekly_max_loss_limit: data.weekly_max_loss_limit,
        weekly_max_loss_start_date: data.weekly_max_loss_start_date,
        weekly_max_loss_end_date: data.weekly_max_loss_end_date,

        daily_max_loss_limit: data.daily_max_loss_limit,
        daily_max_loss_start_date: data.daily_max_loss_start_date,
        daily_max_loss_end_date: data.daily_max_loss_end_date,

        max_stake_start_date: data.max_stake_start_date,
        max_stake_end_date: data.max_stake_end_date,
        max_stake: data.max_stake,

        time_limit_minutes: data.time_limit_minutes,

        banned_start_date: data.banned_start_date,
        banned_end_date: data.banned_end_date,
        banned_status: data.banned_status,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'responsible-gaming-setup-delay',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};


const setMonthlyDepositLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-monthly-deposit-limit',
        site_session_id: data.site_session_id,

        monthly_deposit_limit: data.monthly_deposit_limit,
        monthly_deposit_limit_start_date: data.monthly_deposit_limit_start_date,
        monthly_deposit_limit_end_date: data.monthly_deposit_limit_end_date,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-monthly-deposit-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setWeeklyDepositLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-weekly-deposit-limit',
        site_session_id: data.site_session_id,

        weekly_deposit_limit: data.weekly_deposit_limit,
        weekly_deposit_start_date: data.weekly_deposit_start_date,
        weekly_deposit_end_date: data.weekly_deposit_end_date,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-weekly-deposit-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setDailyDepositLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-daily-deposit-limit',
        site_session_id: data.site_session_id,

        daily_deposit_limit: data.daily_deposit_limit,
        daily_deposit_start_date: data.daily_deposit_start_date,
        daily_deposit_end_date: data.daily_deposit_end_date,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-daily-deposit-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setMonthlyMaxLossLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-monthly-max-loss-limit',
        site_session_id: data.site_session_id,

        monthly_max_loss_limit: data.monthly_max_loss_limit,
        monthly_max_loss_start_date: data.monthly_max_loss_start_date,
        monthly_max_loss_end_date: data.monthly_max_loss_end_date,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-monthly-max-loss-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setWeeklyMaxLossLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-weekly-max-loss-limit',
        site_session_id: data.site_session_id,

        weekly_max_loss_limit: data.weekly_max_loss_limit,
        weekly_max_loss_start_date: data.weekly_max_loss_start_date,
        weekly_max_loss_end_date: data.weekly_max_loss_end_date,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-weekly-max-loss-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setDailyMaxLossLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-daily-max-loss-limit',
        site_session_id: data.site_session_id,

        daily_max_loss_limit: data.daily_max_loss_limit,
        daily_max_loss_start_date: data.daily_max_loss_start_date,
        daily_max_loss_end_date: data.daily_max_loss_end_date,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-daily-max-loss-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setMaxStakeLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-max-stake-limit',
        site_session_id: data.site_session_id,

        max_stake_start_date: data.max_stake_start_date,
        max_stake_end_date: data.max_stake_end_date,
        max_stake: data.max_stake,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-max-stake-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const setSessionTimeLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-session-time-limit',
        site_session_id: data.site_session_id,

        time_limit_minutes: data.time_limit_minutes,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-session-time-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};


const setInactiveAccountForPeriodLimitService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'set-inactive-account-for-period-limit',
        site_session_id: data.site_session_id,

        banned_start_date: data.banned_start_date,
        banned_end_date: data.banned_end_date,
        banned_status: data.banned_status,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'set-inactive-account-for-period-limit',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const banPlayerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'ban-player',
        username: data.username,
        password: data.password,

        date_time: dateTime
    }

    let payload_hash = {
        operation: 'ban-player',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const changePasswordPlayerService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'reset-password',
        site_session_id: data.site_session_id,
        player_id: data.player_id,
        password_old: data.current_password,
        password_new: data.new_password,
        ip_address: '',
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'reset-password',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};


//this database procedure is deleted from its package
const checkIfCountryIsProhibitedService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'check-if-country-is-prohibited',
        affiliate_id: data.affiliate_id,
        ip_address: '',
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'check-if-country-is-prohibited',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const currencyForPromoCodeService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'currency-for-promo-code',
        affiliate_name: data.affiliate_name,
        ip_address: '',
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'currency-for-promo-code',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const playerRegistrationConfirmationService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'player-registration-confirmation',
        hash_id: data.hash_id,
        player_id: data.player_id,
        ip_address: '',
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'player-registration-confirmation',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const checkTemporaryIdService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'check-temporary-id',
        username: data.username,
        id: data.id,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'check-temporary-id',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const changePlayerPasswordService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'change-password',
        username: data.username,
        id: data.id,
        password: data.password,
        question: data.question,
        answer: data.answer,
        date_time: dateTime,
    }

    let payload_hash = {
        operation: 'change-password',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

const unlockPlayerAccountService = (data) => {

    const dateTime = moment(new Date()).format('DD-MM-yyyy hh:mm:ss');

    let payload = {
        operation: 'unlock-account',
        player_id: data.player_id,
        date_time: dateTime
    }

    let payload_hash = {
        operation: 'unlock-account',
        date_time: dateTime,
        username: config.casinoName
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-HMAC-Value': btoa(CryptoJS.HmacSHA256(JSON.stringify(payload_hash), config.JWT_SECRET_KEY).toString()),
            'X-Date': dateTime,
            'X-Username': config.casinoName
        }
    };

    if (config.USE_JWT_IN_RESPONSE) {
        payload['JWT'] = config.JWT_SECRET_KEY;
        let jwt_token = CryptoJS.SHA1(JSON.stringify(payload)).toString();
        payload['JWT'] = jwt_token;
    }

    return fetch(config.REST_SERVICE_BASE_URL, {
        method: 'POST',
        headers: axiosConfig.headers,
        body: JSON.stringify(payload)
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            //console.error(error);
            return {
                status: 'NOK',
                message: 'Error in server request'
            };
        });
};

export {
    getValidatePlayerUsernameService,
    getListCountriesForRegistrationFormService,
    getListCurrencyForRegistrationFormService,
    getCurrencyForPromoCodeService,
    getSecurityQuestionsService,
    setSecurityQuestionAnswerService,
    getSecurityQuestionAnswerService,
    registerPlayerService,
    metamaskRegisterPlayerService,
    updatePlayerInformationService,
    forgotPasswordWithSecurityAnswerService,
    forgotPasswordWithUsernameService,
    forgotUsernameService,
    playerDetailsService,
    sendPlayerActivationMailService,
    getListLimitsForPlayerService,
    responsibleGamingSetupForPlayerService,
    banPlayerService,
    changePasswordPlayerService,
    checkIfCountryIsProhibitedService,
    currencyForPromoCodeService,
    playerRegistrationConfirmationService,
    checkTemporaryIdService,
    changePlayerPasswordService,
    unlockPlayerAccountService,

    setMonthlyDepositLimitService,
    setWeeklyDepositLimitService,
    setDailyDepositLimitService,

    setMonthlyMaxLossLimitService,
    setWeeklyMaxLossLimitService,
    setDailyMaxLossLimitService,

    setMaxStakeLimitService,

    setSessionTimeLimitService,

    setInactiveAccountForPeriodLimitService

}