

export const SET_REPORT_START_DATE = 'SET_REPORT_START_DATE';

const setReportStartDateAction = (data) => ({
    type: SET_REPORT_START_DATE,
    payload: data
});

export const SET_REPORT_END_DATE = 'SET_REPORT_END_DATE';

const setReportEndDateAction = (data) => ({
    type: SET_REPORT_END_DATE,
    payload: data
});

export { 
    setReportStartDateAction,
    setReportEndDateAction,
};