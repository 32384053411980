import {
    SET_REPORT_START_DATE,
    SET_REPORT_END_DATE,
} from "../../actions/reportDate/ReportDateActions";
import * as moment from "moment";

const initialState = {
    reportDate: {
        startDate: moment().startOf("month").format("DD-MMM-YYYY"),
        endDate: moment().format("DD-MMM-YYYY"),
    }
}

let ReportDateReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_REPORT_START_DATE: 
            return {
                ...state, 
                reportDate: {
                    ...state.reportDate,
                    startDate: action.payload
                }
            };
        case SET_REPORT_END_DATE:
            return {
                ...state,
                reportDate: {
                    ...state.reportDate,
                    endDate: action.payload
                }

            }    
        default: 
            return state;    
    }
};

export { ReportDateReducer };