
import { 
    playerDetailsService
} from '../../services/AccountService';

export const SAVE_PLAYER_DETAILS = 'SAVE_PLAYER_DETAILS';
export const GET_PLAYER_DETAILS = 'GET_PLAYER_DETAILS';

const getPlayerDetailsAction = (data) => ({
    type: GET_PLAYER_DETAILS,
    payload: playerDetailsService(data)
});

const savePlayerDetailsAction = (data) => ({
    type: SAVE_PLAYER_DETAILS,
    payload: data
});


export { 
    getPlayerDetailsAction,
    savePlayerDetailsAction
};