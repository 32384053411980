
export const SET_VOUCHER_DEPOSIT_CODE = 'SET_VOUCHER_DEPOSIT_CODE';

const setVoucherDepositCodeAction = (data) => ({
    type: SET_VOUCHER_DEPOSIT_CODE,
    payload: data
});

export { 
    setVoucherDepositCodeAction
};