
import { 
    loginPlayerService,
    logoutPlayerService,
    setTimeModifiedService,
    validateSiteSessionService,
} from '../../services/SessionService';

//import setTimeModifiedWorker from "../../../workers/setTimeModifiedWorker";
//import validateSiteSessionWorker from '../../../workers/validateSiteSessionWorker';

export const LOGIN_PLAYER = 'LOGIN_PLAYER';
export const SAVE_LOGIN_PLAYER = 'SAVE_LOGIN_PLAYER';
export const LOGOUT_PLAYER = 'LOGOUT_PLAYER';
export const SAVE_LOGOUT_PLAYER = 'SAVE_LOGOUT_PLAYER';
export const SET_TIME_MODIFIED = 'SET_TIME_MODIFIED';
export const VALIDATE_SITE_SESSION = 'VALIDATE_SITE_SESSION';

const loginPlayerAction = (loginData) => ({
    type: LOGIN_PLAYER,
    payload: loginPlayerService(loginData)
});

const saveLoginPlayerAction = (loginData) => ({
    type: SAVE_LOGIN_PLAYER,
    payload: loginData
});

const logoutPlayerAction = (logoutData) => ({
    type: LOGOUT_PLAYER,
    payload: logoutPlayerService(logoutData)
});

const saveLogoutPlayerAction = () => ({
    type: SAVE_LOGOUT_PLAYER,
    payload: {}
});

const setTimeModifiedAction = (data) => (
    {
        type: SET_TIME_MODIFIED,
        //payload: setTimeModifiedWorker.settimemodified(data) //setTimeModifiedService(data)
        payload: setTimeModifiedService(data)
    }
)

const validateSiteSessionAction = (data) => (
    {
        type: VALIDATE_SITE_SESSION,
        //payload: validateSiteSessionWorker.validatesitesession(data) 
        payload: validateSiteSessionService(data)
    }
);

export { 
    loginPlayerAction, saveLoginPlayerAction,
    logoutPlayerAction, saveLogoutPlayerAction,
    setTimeModifiedAction, validateSiteSessionAction
};