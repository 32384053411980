import {
    SET_FORGOT_PASSWORD_DIALOG_STATUS,
    DELETE_FORGOT_PASSWORD_DIALOG_STATUS
} from "../../actions/forgotPasswordDialog/ForgotPasswordDialogActions"

const initialState = {
    forgotPasswordDialogStatus: {}
}

let ForgotPasswordDialogStatusReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_FORGOT_PASSWORD_DIALOG_STATUS: 
            return {
                ...state, 
                forgotPasswordDialogStatus: action.payload,
            };
        case DELETE_FORGOT_PASSWORD_DIALOG_STATUS: 
            return {
                initialState
            };     
        default: 
            return state;    
    }
};

export { ForgotPasswordDialogStatusReducer };