import {
    SET_LOGIN_DIALOG_STATUS,
    DELETE_LOGIN_DIALOG_STATUS
} from "../../actions/loginDialog/LoginDialogActions"

const initialState = {
    loginDialogStatus: {}
}

let LoginDialogStatusReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_LOGIN_DIALOG_STATUS: 
            return {
                ...state, 
                loginDialogStatus: action.payload,
            };
        case DELETE_LOGIN_DIALOG_STATUS: 
            return {
                initialState
            };     
        default: 
            return state;    
    }
};

export { LoginDialogStatusReducer };