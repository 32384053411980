
export const SET_FORGOT_PASSWORD_DIALOG_STATUS = 'SET_FORGOT_PASSWORD_DIALOG_STATUS';

export const DELETE_FORGOT_PASSWORD_DIALOG_STATUS = 'DELETE_FORGOT_PASSWORD_DIALOG_STATUS';

const setForgotPasswordDialogStatusAction = (data) => ({
    type: SET_FORGOT_PASSWORD_DIALOG_STATUS,
    payload: data
});

const deleteForgotPasswordDialogStatusAction = () => ({
    type: DELETE_FORGOT_PASSWORD_DIALOG_STATUS,
    payload: {}
});

export { 
    setForgotPasswordDialogStatusAction,
    deleteForgotPasswordDialogStatusAction
};