import {
    SET_VIEW_BONUS_PROMO,
} from "../../actions/viewBonusPromo/ViewBonusPromoActions"

const initialState = {
    viewBonusPromo: {}
}

let ViewBonusPromoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VIEW_BONUS_PROMO:
            return {
                ...state,
                viewBonusPromo: action.payload,
            };
        default:
            return state;
    }
};

export { ViewBonusPromoReducer };