

export const SET_TID_FOR_REGISTRATION = 'SET_TID_FOR_REGISTRATION';

const setTidForRegistrationAction = (data) => ({
    type: SET_TID_FOR_REGISTRATION,
    payload: data
});

export { 
    setTidForRegistrationAction
};