import {
    SET_SELECTED_PROMO,
} from "../../actions/selectedPromo/PromoActions"

const initialState = {
    selectedPromo: {}
}

let PromoReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SELECTED_PROMO:
            return {
                ...state, 
                selectedPromo: action.payload,
            };
        default: 
            return state;    
    }
};

export { PromoReducer };