

export const SET_COUNTRY_IS_PROHIBITED_STATUS = 'SET_COUNTRY_IS_PROHIBITED_STATUS';

const setCountryIsProhibitedAction = (data) => ({
    type: SET_COUNTRY_IS_PROHIBITED_STATUS,
    payload: data
});

export { 
    setCountryIsProhibitedAction,
};