import {
    SET_NOTIFICATION,
    REMOVE_NOTIFICATION
} from "../../actions/notifications/NotificationsAction"

const initialState = {
    notifications: null
}

let NotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                notifications: action.payload,
            };
        case REMOVE_NOTIFICATION:
            return initialState;
        default:
            return state;
    }
};

export { NotificationsReducer };