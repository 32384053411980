import {
    SET_FORGOT_USERNAME_DIALOG_STATUS,
    DELETE_FORGOT_USERNAME_DIALOG_STATUS
} from "../../actions/forgotUsernameDialog/ForgotUsernameDialogActions"

const initialState = {
    forgotUsernameDialogStatus: {}
}

let ForgotUsernameDialogStatusReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_FORGOT_USERNAME_DIALOG_STATUS: 
            return {
                ...state, 
                forgotUsernameDialogStatus: action.payload,
            };
        case DELETE_FORGOT_USERNAME_DIALOG_STATUS: 
            return {
                initialState
            };     
        default: 
            return state;    
    }
};

export { ForgotUsernameDialogStatusReducer };