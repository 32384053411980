
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

const setNotificationAction = (data) => ({
    type: SET_NOTIFICATION,
    payload: data
});

const removeNotificationAction = (data) => ({
    type: REMOVE_NOTIFICATION,
    payload: data
})

export { 
    setNotificationAction,
    removeNotificationAction
};