
export const SET_PLAYER_PLAYING_GAME = 'SET_PLAYER_PLAYING_GAME';

const setPlayerPlayingGameAction = (data) => ({
    type: SET_PLAYER_PLAYING_GAME,
    payload: data
});

export { 
    setPlayerPlayingGameAction
};