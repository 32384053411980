import {
    SET_PLAYER_PLAYING_GAME
} from "../../actions/playerPlayingGame/PlayerPlayingGameActions"

const initialState = {
    playerPlayingGame: {}
}

let PlayerPlayingGameReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PLAYER_PLAYING_GAME: 
            return {
                ...state, 
                playerPlayingGame: action.payload,
            };        
        default: 
            return state;    
    }
};

export { PlayerPlayingGameReducer };