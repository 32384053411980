
export const SET_VIVO_GAMING_INTEGRATION_TOKEN = 'SET_VIVO_GAMING_INTEGRATION_TOKEN';

export const DELETE_VIVO_GAMING_INTEGRATION_TOKEN = 'DELETE_VIVO_GAMING_INTEGRATION_TOKEN';

const setVivoGamingIntegrationTokenAction = (data) => ({
    type: SET_VIVO_GAMING_INTEGRATION_TOKEN,
    payload: data
});

const deleteVivoGamingIntegrationTokenAction = () => ({
    type: DELETE_VIVO_GAMING_INTEGRATION_TOKEN,
    payload: null
});


export { 
    setVivoGamingIntegrationTokenAction,
    deleteVivoGamingIntegrationTokenAction
};