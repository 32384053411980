
export const SET_SEARCH_GAME = 'SET_SEARCH_GAME';

const setSearchGameInputAction = (data) => ({
    type: SET_SEARCH_GAME,
    payload: data
});

export { 
    setSearchGameInputAction
};