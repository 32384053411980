// IMPORT PACKAGE REFERENCES

import { combineReducers } from 'redux';

// IMPORT REDUCERS

import { SessionReducer } from './session/SessionReducer';
import { CreditReducer } from './credit/CreditReducer';
import { CookieConsentReducer } from './cookieConsent/CookieConsentReducer';
import { PlayerDetailsReducer } from './playerDetails/PlayerDetailsReducer';
import { VerificationStatusReducer } from "./verificationStatus/VerificationStatusReducer";
import { PromoReducer } from "./selectedPromo/PromoReducer";
import { PlayerPlayingGameReducer } from './playerPlayingGame/PlayerPlayingGameReducer';
import { CountryIsProhibitedReducer } from './countryIsProhibited/CountryIsProhibitedReducer';
import { LicenceInformationReducer } from "./licenceInformation/LicenceInformationReducer";
import { TidForRegistrationReducer } from "./tidForRegistration/TidForRegistrationReducer";
import { TargetPageAfterLoginReducer } from "./targetPageAfterLogin/TargetPageAfterLoginReducer";
import { RegistrationCodeForRegistrationReducer } from "./registrationCodeForRegistration/RegistrationCodeForRegistrationReducer";
import { ListAnonymousGamesForDesktopReducer } from "./listAnonymousGamesForDesktop/ListAnonymousGamesForDesktopReducer";
import { ListAnonymousGamesForMobileReducer } from "./listAnonymousGamesForMobile/ListAnonymousGamesForMobileReducer";
import { ListCurrentJackpotLevelsReducer } from "./listCurrentJackpotLevels/ListCurrentJackpotLevelsReducer";
import { ListPromosAndBonusesReducer } from "./listPromosAndBonuses/ListPromosAndBonusesReducer";
import { ReportDateReducer } from "./reportDate/ReportDateReducer";
import { LoginDialogStatusReducer } from './loginDialog/LoginDialogReducer';
import { RegistrationDialogStatusReducer } from './registrationDialog/RegistrationDialogReducer';
import { SubmenuDialogStatusReducer } from './submenuDialog/SubmenuDialogReducer';
import { PlayGameDialogStatusReducer } from './playGameDialog/PlayGameDialogReducer';
import { AuthenticatedPlayerDialogStatusReducer } from './authenticatedPlayerDialog/AuthenticatedPlayerDialogReducer';
import { ForgotPasswordDialogStatusReducer } from './forgotPasswordDialog/ForgotPasswordDialogReducer';
import { ForgotUsernameDialogStatusReducer } from './forgotUsernameDialog/ForgotUsernameDialogReducer';
import { ListFavouriteGamesReducer } from './listFavouriteGames/ListFavouriteGamesReducer';
import { SearchGameReducer } from './searchGame/SearchGameReducer';
import { VivoGamingIntegrationReducer } from './vivoGamingIntegration/VivoGamingIntegrationReducer';
import { IpAddressReducer } from './ipAddress/IpAddressReducer';
import { NotificationsReducer } from './notifications/NotificationsReducer';
import { VoucherDepositCodeReducer } from './voucherDepositCode/VoucherDepositCodeReducer';
import { ViewBonusPromoReducer } from './viewBonusPromo/ViewBonusPromoReducer';

// EXPORT APP REDUCER

export const AppReducer = combineReducers({
    session: SessionReducer,
    credit: CreditReducer,
    cookieConsent: CookieConsentReducer,
    playerDetails: PlayerDetailsReducer,
    verificationStatus: VerificationStatusReducer,
    selectedPromo: PromoReducer,
    playerPlayingGame: PlayerPlayingGameReducer,
    countryIsProhibitedStatus: CountryIsProhibitedReducer,
    licenceInformation: LicenceInformationReducer,
    tidForRegistration: TidForRegistrationReducer,
    targetPageAfterLogin: TargetPageAfterLoginReducer,
    registrationCodeForRegistration: RegistrationCodeForRegistrationReducer,
    listAnonymousGamesForDesktop: ListAnonymousGamesForDesktopReducer,
    listAnonymousGamesForMobile: ListAnonymousGamesForMobileReducer,
    listCurrentJackpotLevels: ListCurrentJackpotLevelsReducer,
    listPromosAndBonuses: ListPromosAndBonusesReducer,
    reportDate: ReportDateReducer,
    loginDialogStatus: LoginDialogStatusReducer,
    registrationDialogStatus: RegistrationDialogStatusReducer,
    submenuDialogStatus: SubmenuDialogStatusReducer,
    playGameDialogStatus: PlayGameDialogStatusReducer,
    authenticatedPlayerDialogStatus: AuthenticatedPlayerDialogStatusReducer,
    forgotPasswordDialogStatus: ForgotPasswordDialogStatusReducer,
    forgotUsernameDialogStatus: ForgotUsernameDialogStatusReducer,
    listFavouriteGames: ListFavouriteGamesReducer,
    searchGame: SearchGameReducer,
    vivoGamingIntegration: VivoGamingIntegrationReducer,
    ipAddress: IpAddressReducer,
    notifications: NotificationsReducer,
    voucherDepositCode: VoucherDepositCodeReducer,
    viewBonusPromo: ViewBonusPromoReducer,
});