
export const SET_VIEW_BONUS_PROMO = 'SET_VIEW_BONUS_PROMO';

const setViewBonusPromoAction = (data) => ({
    type: SET_VIEW_BONUS_PROMO,
    payload: data
});

export {
    setViewBonusPromoAction
};