
export const ADD_FAVOURITE_GAME = 'ADD_FAVOURITE_GAME';
export const REMOVE_FAVOURITE_GAME = 'REMOVE_FAVOURITE_GAME';

const addFavouriteGameAction = (data) => ({
    type: ADD_FAVOURITE_GAME,
    payload: data
});

const removeFavouriteGameAction = (data) => ({
    type: REMOVE_FAVOURITE_GAME,
    payload: data
})

export { 
    addFavouriteGameAction,
    removeFavouriteGameAction
};