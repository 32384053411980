import {
    CHANGE_PLAYER_VERIFICATION_STATUS,
    DELETE_PLAYER_VERIFICATION_STATUS
} from "../../actions/verificationStatus/VerificationStatusActions"

const initialState = {
    verificationStatus: {
        not_verified: true,
        email_verified: false,
        kyc_verified: false
    }
}

let VerificationStatusReducer = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_PLAYER_VERIFICATION_STATUS: 
            return {
                ...state, 
                verificationStatus: action.payload,
            };
        case DELETE_PLAYER_VERIFICATION_STATUS:
            return {
                ...state,
                verificationStatus: {}
            };
        default: 
            return state;    
    }
};

export { VerificationStatusReducer };