import {
    SET_COUNTRY_IS_PROHIBITED_STATUS
} from "../../actions/countryIsProhibited/CountryIsProhibitedActions"

const initialState = {
    countryIsProhibitedStatus: {}
}

let CountryIsProhibitedReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_COUNTRY_IS_PROHIBITED_STATUS: 
            return {
                ...state, 
                countryIsProhibitedStatus: action.payload,
            };        
        default: 
            return state;    
    }
};

export { CountryIsProhibitedReducer };