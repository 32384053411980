import {
    SET_PLAYER_CREDIT,
    GET_PLAYER_CREDIT,
    DELETE_PLAYER_CREDIT,
} from "../../actions/credit/CreditActions"

const initialState = {
    credit: {}
}

let CreditReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PLAYER_CREDIT: 
            return {
                ...state, 
                credit: action.payload,
            };
        case DELETE_PLAYER_CREDIT:
           return initialState;
        case GET_PLAYER_CREDIT:
            return {
                ...state,
                credit: action.payload
            };
        default: 
            return state;    
    }
};

export { CreditReducer };