import {
    SET_SEARCH_GAME,
} from "../../actions/searchGame/SearchGameActions"

const initialState = {
    searchGame: ''
}

let SearchGameReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SEARCH_GAME:
            return {
                ...state, 
                searchGame: action.payload,
            };
        default: 
            return state;    
    }
};

export { SearchGameReducer };