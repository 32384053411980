
export const SET_IP_ADDRESS = 'SET_IP_ADDRESS';
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';
export const DELETE_IP_ADDRESS = 'DELETE_IP_ADDRESS';

const setIpAddressAction = (data) => ({
    type: SET_IP_ADDRESS,
    payload: data
});

const getIpAddressAction = (data) => ({
    type: GET_IP_ADDRESS,
    payload: data
});

const deleteIpAddressAction = () => ({
    type: DELETE_IP_ADDRESS,
    payload: ''
});

export { 
    setIpAddressAction, getIpAddressAction,
    deleteIpAddressAction,
};