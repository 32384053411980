
export const SET_PLAY_GAME_DIALOG_STATUS = 'SET_PLAY_GAME_DIALOG_STATUS';

export const DELETE_PLAY_GAME_DIALOG_STATUS = 'DELETE_PLAY_GAME_DIALOG_STATUS';

const setPlayGameDialogStatusAction = (data) => ({
    type: SET_PLAY_GAME_DIALOG_STATUS,
    payload: data
});

const deletePlayGameDialogStatusAction = () => ({
    type: DELETE_PLAY_GAME_DIALOG_STATUS,
    payload: {}
});

export { 
    setPlayGameDialogStatusAction,
    deletePlayGameDialogStatusAction
};