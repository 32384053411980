
//DEV

export const ENVIRONMENT_SITE = 'DEV';
export const IS_DEV_ENV = false;
export const REST_SERVICE_BASE_URL = "https://dev.888bits.com/api/rest/index";
export const UPLOAD_PLAYER_DOCUMENT_SERVICE_URL = "https://dev.888bits.com/api/player-document-upload/upload";
export const BASE_URL = "https://dev.888bits.com/";
export const UPLOADED_DOCUMENTS_LOCATION = "https://dev.888bits.com"; // + uploads/player_name + / + file_name
export const APCO_PURCHASE_URL = "http://192.168.3.100/test-integration/public/ultimate777/apco/purchase";
export const HTML5_GAME_BASE_URL = "https://dev.888bits.com/games/";
export const HTML5_GAME_BASE_URL2 = "https://dev.888bits.com";
export const internetGroupId = '747872399';
export const S8B_SITE_URL = "https://dev.s8b.888bits.com/";
export const API_BASE_URL = "https://dev.s8b.888bits.com/api/";

export const easitIntegrationInternetGroupAffiliateName = 'MAXBETRS';
export const easitIntegrationInternetGroupId = '747872158';
//local or session storage protection - easit integration page
export const ENCRYPT_EASIT_INTEGRATION_STORAGE = true;
export const ENCRYPT_EASIT_INTEGATION_STORAGE_SECRET_KEY = 'ultraplus';
export const EASIT_INTEGRATION_STORAGE_NAME = 'dev_888bits_com_easit_integration';
export const EASIT_HTML5_GAME_BASE_URL = "https://dev.888bits.com/games/";
//local or session storage protection - player session in web site
export const ENCRYPT_APP_STATE_TO_STORAGE = true;
export const ENCRYPT_APP_STATE_TO_STORAGE_SECRET_KEY = 'ultraplus';
export const APP_STATE_TO_STORAGE_NAME = 'dev_888bits_com';
//GDPR cookie name for privacy policy modal dialog
export const GDPR_COOKIE_NAME = 'GDPR_DEV_888BITS_COM';

export const ENCRYPT_SEAMLESS_INTEGRATION_STORAGE = true;
export const ENCRYPT_SEAMLESS_INTEGRATION_STORAGE_SECRET_KEY = 'ultraplus';
export const SEAMLESS_INTEGRATION_STORAGE_NAME = 'dev_888bits_com_seamless_integration';
export const SEAMLESS_HTML5_GAME_BASE_URL = "https://dev.888bits.com/games/";

export const cryptoCurrencyList = ['LTC', 'LTCT', 'BTC', 'ETH', 'ZEC', 'XMR', 'USDT', 'DOGE', 'MBTC', 'METH', 'μBTC', 'μETH', 'mBTC', 'uBTC', 'mETH', 'BCH', 'USDTT', 'USDTE', 'USDC'];

export const getMinimumFractionDigits = (currency: string) => {
    if (currency) {
        let testCurrency = currency.toUpperCase();

        if (testCurrency === 'USDT'.toUpperCase()) {
            return 2;
        }
        if (testCurrency === 'USDTE'.toUpperCase()) {
            return 2;
        }
        if (testCurrency === 'USDTT'.toUpperCase()) {
            return 2;
        }
        if (testCurrency === 'USDC'.toUpperCase()) {
            return 2;
        }
        if (testCurrency === 'DOGE'.toUpperCase()) {
            return 4;
        }
        if (testCurrency === 'BCH'.toUpperCase()) {
            return 7;
        }
        if (testCurrency === 'LTC'.toUpperCase()) {
            return 7;
        }
        if (testCurrency === 'LTCT'.toUpperCase()) {
            return 7;
        }

        if (cryptoCurrencyList.includes(testCurrency)) {
            return 8;
        } else {
            return 2;
        }
    }
    if (currency) {

        if (currency === 'USDT') {
            return 2;
        }
        if (currency === 'USDTE') {
            return 2;
        }
        if (currency === 'USDTT') {
            return 2;
        }
        if (currency === 'USDC') {
            return 2;
        }
        if (currency === 'DOGE') {
            return 4;
        }
        if (currency === 'BCH') {
            return 7;
        }
        if (currency === 'LTC') {
            return 7;
        }
        if (currency === 'LTCT') {
            return 7;
        }

        if (cryptoCurrencyList.includes(currency)) {
            return 8;
        } else {
            return 2;
        }
    }
}

export const casinoName = '888bits';
export const casinoDomainName = '888bits.com';

export const affiliateName = '888bits.com';
export const whiteLabelName = '888bits';
export const siteNameForGame = '888bits.com';
//export const defaultCurrencyName = 'USDT';
//export const defaultCurrencyId = '003';
export const defaultCurrencyName = 'USDTE';
export const defaultCurrencyId = '007';
export const defaultSupportMail = 'support@888bits.com';//'support@netzonelab.com';

export const VIVO_GAMING_INTEGRATION_IS_DEMO = false;
export const VIVO_GAMING_DEMO_OPERATOR_ID = 30155;

export const HOME_PAGE = '/';

//debug general calls in console
export const DEBUG_CONSOLE = false;
//debug payments in console
export const DEBUG_PAYMENT_CONSOLE = false;
//debug EASIT integration page
export const DEBUG_EASIT_CONSOLE = false;
//clear console debug
export const CLEAR_CONSOLE_DEBUG = false;

//jwt protected rest services
export const USE_JWT_IN_RESPONSE = true;
export const JWT_SECRET_KEY = 'ultraplus';

//ping player credits
export const PING_PLAYER_CREDITS_TIME_DELAY = 2000; //2000 ms
export const PING_PLAYER_CREDITS_TIME_DELAY_WHILE_PLAYING = 1000; //6000 ms

//ping player details when Not Verified status of player
export const PING_PLAYER_DETAILS_TIME_DELAY = 30000; //ms

//call Set Time Modified to show database that web site is working
export const PING_SET_TIME_MODIFIED_TIME_DELAY = 50000; //ms

//call player valid site session or logout player time delay
export const PING_VALIDATE_SITE_SESSION_TIME_DELAY = 20000; //20 sec
//call to notify active site session
export const NOTIFY_ACTIVE_SITE_SESSION_TIME_DELAY = 1500000; //25 min

//Get Licence Information
export const PING_GET_LICENCE_INFORMATION_TIME_DELAY = 60000; //ms

//Limit reports in past 6 months
export const LIMIT_REPORTS_PAST = 6;

export const MINIMUM_FRACTION_DIGITS = 6;

//upload player documents relative location
export const UPLOADED_DOCUMENTS_DIRECTORY = 'uploads';

export const APP_VERSION = "1.3.3";