
export const SET_PLAYER_CREDIT = 'SET_PLAYER_CREDIT';
export const GET_PLAYER_CREDIT = 'GET_PLAYER_CREDIT';
export const DELETE_PLAYER_CREDIT = 'DELETE_PLAYER_CREDIT';

const setPlayerCreditAction = (data) => ({
    type: SET_PLAYER_CREDIT,
    payload: data
});

const getPlayerCreditAction = (data) => ({
    type: GET_PLAYER_CREDIT,
    payload: data
});

const deletePlayerCreditAction = () => ({
    type: DELETE_PLAYER_CREDIT,
    payload: {}
});

export { 
    setPlayerCreditAction, getPlayerCreditAction,
    deletePlayerCreditAction,
};