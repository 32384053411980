import {
    SET_IP_ADDRESS,
    GET_IP_ADDRESS,
    DELETE_IP_ADDRESS,
} from "../../actions/ipAddress/IpAddressActions"

const initialState = {
    ipAddress: ''
}

let IpAddressReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_IP_ADDRESS: 
            return {
                ...state, 
                ipAddress: action.payload,
            };
        case DELETE_IP_ADDRESS:
           return initialState;
        case GET_IP_ADDRESS:
            return {
                ...state,
                ipAddress: action.payload
            };
        default: 
            return state;    
    }
};

export { IpAddressReducer };