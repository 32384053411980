import {
    LOGIN_PLAYER,
    SAVE_LOGIN_PLAYER,
    LOGOUT_PLAYER,
    SAVE_LOGOUT_PLAYER,
    SET_TIME_MODIFIED,
    VALIDATE_SITE_SESSION
} from "../../actions/session/SessionActions"

const initialState = {
    session: {}
}

let SessionReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_PLAYER: 
            return {
                ...state, 
                session: action.payload,
            };
        case SAVE_LOGIN_PLAYER:
            return {
                ...state,
                session: action.payload
            };
        case LOGOUT_PLAYER:
            return initialState;
        case SAVE_LOGOUT_PLAYER:
            return initialState;
        case SET_TIME_MODIFIED:
            return state;
        case VALIDATE_SITE_SESSION:
            return state;
        default: 
            return state;    
    }
};

export { SessionReducer };