
export const SET_LICENCE_INFORMATION = 'SET_LICENCE_INFORMATION';

const setLicenceInformationAction = (data) => ({
    type: SET_LICENCE_INFORMATION,
    payload: data
});

export { 
    setLicenceInformationAction
};