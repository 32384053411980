import {
    SET_ANONYMOUS_GAMES_FOR_DESKTOP    
} from "../../actions/listAnonymousGamesForDesktop/ListAnonymousGamesForDesktopAction"

const initialState = {
    listAnonymousGamesForDesktop: {}
}

let ListAnonymousGamesForDesktopReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ANONYMOUS_GAMES_FOR_DESKTOP: 
            return {
                ...state, 
                listAnonymousGamesForDesktop: action.payload,
            };        
        default: 
            return state;    
    }
};

export { ListAnonymousGamesForDesktopReducer };