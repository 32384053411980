import {
    SET_VIVO_GAMING_INTEGRATION_TOKEN,
    DELETE_VIVO_GAMING_INTEGRATION_TOKEN
} from "../../actions/vivoGamingIntegration/VivoGamingIntegrationActions"

const initialState = {
    vivoGamingIntegration: null
}

let VivoGamingIntegrationReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_VIVO_GAMING_INTEGRATION_TOKEN: 
            return {
                ...state, 
                vivoGamingIntegration: action.payload,
            };
        case DELETE_VIVO_GAMING_INTEGRATION_TOKEN:
            return {
                ...state,
                vivoGamingIntegration: null
            };
        default: 
            return state;    
    }
};

export { VivoGamingIntegrationReducer };