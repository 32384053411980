
export const SET_SELECTED_PROMO = 'SET_SELECTED_PROMO';

const setSelectedPromoAction = (data) => ({
    type: SET_SELECTED_PROMO,
    payload: data
});

export { 
    setSelectedPromoAction
};