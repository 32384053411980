exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-404-js": () => import("./../../../src/pages/about/404.js" /* webpackChunkName: "component---src-pages-about-404-js" */),
  "component---src-pages-about-affiliates-js": () => import("./../../../src/pages/about/affiliates.js" /* webpackChunkName: "component---src-pages-about-affiliates-js" */),
  "component---src-pages-about-company-js": () => import("./../../../src/pages/about/company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-about-fairness-and-rng-testing-methods-js": () => import("./../../../src/pages/about/fairness-and-rng-testing-methods.js" /* webpackChunkName: "component---src-pages-about-fairness-and-rng-testing-methods-js" */),
  "component---src-pages-about-player-account-js": () => import("./../../../src/pages/about/player-account.js" /* webpackChunkName: "component---src-pages-about-player-account-js" */),
  "component---src-pages-about-terms-and-conditions-js": () => import("./../../../src/pages/about/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-about-terms-and-conditions-js" */),
  "component---src-pages-activation-js": () => import("./../../../src/pages/activation.js" /* webpackChunkName: "component---src-pages-activation-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-de-about-affiliates-js": () => import("./../../../src/pages/de/about/affiliates.js" /* webpackChunkName: "component---src-pages-de-about-affiliates-js" */),
  "component---src-pages-de-about-company-js": () => import("./../../../src/pages/de/about/company.js" /* webpackChunkName: "component---src-pages-de-about-company-js" */),
  "component---src-pages-de-about-fairness-and-rng-testing-methods-js": () => import("./../../../src/pages/de/about/fairness-and-rng-testing-methods.js" /* webpackChunkName: "component---src-pages-de-about-fairness-and-rng-testing-methods-js" */),
  "component---src-pages-de-about-player-account-js": () => import("./../../../src/pages/de/about/player-account.js" /* webpackChunkName: "component---src-pages-de-about-player-account-js" */),
  "component---src-pages-de-about-terms-and-conditions-js": () => import("./../../../src/pages/de/about/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-de-about-terms-and-conditions-js" */),
  "component---src-pages-de-resources-aml-policy-js": () => import("./../../../src/pages/de/resources/aml-policy.js" /* webpackChunkName: "component---src-pages-de-resources-aml-policy-js" */),
  "component---src-pages-de-resources-kyc-policy-js": () => import("./../../../src/pages/de/resources/kyc-policy.js" /* webpackChunkName: "component---src-pages-de-resources-kyc-policy-js" */),
  "component---src-pages-de-resources-privacy-policy-js": () => import("./../../../src/pages/de/resources/privacy-policy.js" /* webpackChunkName: "component---src-pages-de-resources-privacy-policy-js" */),
  "component---src-pages-de-resources-responsible-gaming-js": () => import("./../../../src/pages/de/resources/responsible-gaming.js" /* webpackChunkName: "component---src-pages-de-resources-responsible-gaming-js" */),
  "component---src-pages-de-resources-self-exclusion-js": () => import("./../../../src/pages/de/resources/self-exclusion.js" /* webpackChunkName: "component---src-pages-de-resources-self-exclusion-js" */),
  "component---src-pages-de-resources-support-and-complaints-js": () => import("./../../../src/pages/de/resources/support-and-complaints.js" /* webpackChunkName: "component---src-pages-de-resources-support-and-complaints-js" */),
  "component---src-pages-easit-integration-js": () => import("./../../../src/pages/easit-integration.js" /* webpackChunkName: "component---src-pages-easit-integration-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-affiliates-js": () => import("./../../../src/pages/en/about/affiliates.js" /* webpackChunkName: "component---src-pages-en-about-affiliates-js" */),
  "component---src-pages-en-about-company-js": () => import("./../../../src/pages/en/about/company.js" /* webpackChunkName: "component---src-pages-en-about-company-js" */),
  "component---src-pages-en-about-fairness-and-rng-testing-methods-js": () => import("./../../../src/pages/en/about/fairness-and-rng-testing-methods.js" /* webpackChunkName: "component---src-pages-en-about-fairness-and-rng-testing-methods-js" */),
  "component---src-pages-en-about-player-account-js": () => import("./../../../src/pages/en/about/player-account.js" /* webpackChunkName: "component---src-pages-en-about-player-account-js" */),
  "component---src-pages-en-about-terms-and-conditions-js": () => import("./../../../src/pages/en/about/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-en-about-terms-and-conditions-js" */),
  "component---src-pages-en-resources-aml-policy-js": () => import("./../../../src/pages/en/resources/aml-policy.js" /* webpackChunkName: "component---src-pages-en-resources-aml-policy-js" */),
  "component---src-pages-en-resources-kyc-policy-js": () => import("./../../../src/pages/en/resources/kyc-policy.js" /* webpackChunkName: "component---src-pages-en-resources-kyc-policy-js" */),
  "component---src-pages-en-resources-privacy-policy-js": () => import("./../../../src/pages/en/resources/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-resources-privacy-policy-js" */),
  "component---src-pages-en-resources-responsible-gaming-js": () => import("./../../../src/pages/en/resources/responsible-gaming.js" /* webpackChunkName: "component---src-pages-en-resources-responsible-gaming-js" */),
  "component---src-pages-en-resources-self-exclusion-js": () => import("./../../../src/pages/en/resources/self-exclusion.js" /* webpackChunkName: "component---src-pages-en-resources-self-exclusion-js" */),
  "component---src-pages-en-resources-support-and-complaints-js": () => import("./../../../src/pages/en/resources/support-and-complaints.js" /* webpackChunkName: "component---src-pages-en-resources-support-and-complaints-js" */),
  "component---src-pages-exit-game-js": () => import("./../../../src/pages/exit-game.js" /* webpackChunkName: "component---src-pages-exit-game-js" */),
  "component---src-pages-game-js": () => import("./../../../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instant-games-404-js": () => import("./../../../src/pages/instant-games/404.js" /* webpackChunkName: "component---src-pages-instant-games-404-js" */),
  "component---src-pages-instant-games-[gameprovidername]-js": () => import("./../../../src/pages/instant-games/[gameprovidername].js" /* webpackChunkName: "component---src-pages-instant-games-[gameprovidername]-js" */),
  "component---src-pages-instant-games-all-games-js": () => import("./../../../src/pages/instant-games/all-games.js" /* webpackChunkName: "component---src-pages-instant-games-all-games-js" */),
  "component---src-pages-instant-games-js": () => import("./../../../src/pages/instant-games.js" /* webpackChunkName: "component---src-pages-instant-games-js" */),
  "component---src-pages-live-casino-404-js": () => import("./../../../src/pages/live-casino/404.js" /* webpackChunkName: "component---src-pages-live-casino-404-js" */),
  "component---src-pages-live-casino-[gameprovidername]-js": () => import("./../../../src/pages/live-casino/[gameprovidername].js" /* webpackChunkName: "component---src-pages-live-casino-[gameprovidername]-js" */),
  "component---src-pages-live-casino-all-games-js": () => import("./../../../src/pages/live-casino/all-games.js" /* webpackChunkName: "component---src-pages-live-casino-all-games-js" */),
  "component---src-pages-live-casino-baccarat-js": () => import("./../../../src/pages/live-casino/baccarat.js" /* webpackChunkName: "component---src-pages-live-casino-baccarat-js" */),
  "component---src-pages-live-casino-blackjack-js": () => import("./../../../src/pages/live-casino/blackjack.js" /* webpackChunkName: "component---src-pages-live-casino-blackjack-js" */),
  "component---src-pages-live-casino-js": () => import("./../../../src/pages/live-casino.js" /* webpackChunkName: "component---src-pages-live-casino-js" */),
  "component---src-pages-live-casino-poker-js": () => import("./../../../src/pages/live-casino/poker.js" /* webpackChunkName: "component---src-pages-live-casino-poker-js" */),
  "component---src-pages-live-casino-roulette-js": () => import("./../../../src/pages/live-casino/roulette.js" /* webpackChunkName: "component---src-pages-live-casino-roulette-js" */),
  "component---src-pages-load-game-js": () => import("./../../../src/pages/load-game.js" /* webpackChunkName: "component---src-pages-load-game-js" */),
  "component---src-pages-promo-details-js": () => import("./../../../src/pages/promo-details.js" /* webpackChunkName: "component---src-pages-promo-details-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-resources-404-js": () => import("./../../../src/pages/resources/404.js" /* webpackChunkName: "component---src-pages-resources-404-js" */),
  "component---src-pages-resources-aml-policy-js": () => import("./../../../src/pages/resources/aml-policy.js" /* webpackChunkName: "component---src-pages-resources-aml-policy-js" */),
  "component---src-pages-resources-kyc-policy-js": () => import("./../../../src/pages/resources/kyc-policy.js" /* webpackChunkName: "component---src-pages-resources-kyc-policy-js" */),
  "component---src-pages-resources-privacy-policy-js": () => import("./../../../src/pages/resources/privacy-policy.js" /* webpackChunkName: "component---src-pages-resources-privacy-policy-js" */),
  "component---src-pages-resources-responsible-gaming-js": () => import("./../../../src/pages/resources/responsible-gaming.js" /* webpackChunkName: "component---src-pages-resources-responsible-gaming-js" */),
  "component---src-pages-resources-self-exclusion-js": () => import("./../../../src/pages/resources/self-exclusion.js" /* webpackChunkName: "component---src-pages-resources-self-exclusion-js" */),
  "component---src-pages-resources-support-and-complaints-js": () => import("./../../../src/pages/resources/support-and-complaints.js" /* webpackChunkName: "component---src-pages-resources-support-and-complaints-js" */),
  "component---src-pages-seamless-integration-js": () => import("./../../../src/pages/seamless-integration.js" /* webpackChunkName: "component---src-pages-seamless-integration-js" */),
  "component---src-pages-slot-404-js": () => import("./../../../src/pages/slot/404.js" /* webpackChunkName: "component---src-pages-slot-404-js" */),
  "component---src-pages-slot-5-men-js": () => import("./../../../src/pages/slot/5men.js" /* webpackChunkName: "component---src-pages-slot-5-men-js" */),
  "component---src-pages-slot-7-mojos-js": () => import("./../../../src/pages/slot/7-mojos.js" /* webpackChunkName: "component---src-pages-slot-7-mojos-js" */),
  "component---src-pages-slot-[gameprovidername]-js": () => import("./../../../src/pages/slot/[gameprovidername].js" /* webpackChunkName: "component---src-pages-slot-[gameprovidername]-js" */),
  "component---src-pages-slot-act-js": () => import("./../../../src/pages/slot/act.js" /* webpackChunkName: "component---src-pages-slot-act-js" */),
  "component---src-pages-slot-arrows-edge-js": () => import("./../../../src/pages/slot/arrows-edge.js" /* webpackChunkName: "component---src-pages-slot-arrows-edge-js" */),
  "component---src-pages-slot-bbtech-js": () => import("./../../../src/pages/slot/bbtech.js" /* webpackChunkName: "component---src-pages-slot-bbtech-js" */),
  "component---src-pages-slot-betsoft-js": () => import("./../../../src/pages/slot/betsoft.js" /* webpackChunkName: "component---src-pages-slot-betsoft-js" */),
  "component---src-pages-slot-js": () => import("./../../../src/pages/slot.js" /* webpackChunkName: "component---src-pages-slot-js" */),
  "component---src-pages-slot-leap-js": () => import("./../../../src/pages/slot/leap.js" /* webpackChunkName: "component---src-pages-slot-leap-js" */),
  "component---src-pages-slot-nucleus-js": () => import("./../../../src/pages/slot/nucleus.js" /* webpackChunkName: "component---src-pages-slot-nucleus-js" */),
  "component---src-pages-slot-platipus-js": () => import("./../../../src/pages/slot/platipus.js" /* webpackChunkName: "component---src-pages-slot-platipus-js" */),
  "component---src-pages-slot-red-eagle-js": () => import("./../../../src/pages/slot/red-eagle.js" /* webpackChunkName: "component---src-pages-slot-red-eagle-js" */),
  "component---src-pages-slot-red-rake-js": () => import("./../../../src/pages/slot/red-rake.js" /* webpackChunkName: "component---src-pages-slot-red-rake-js" */),
  "component---src-pages-slot-redeagle-js": () => import("./../../../src/pages/slot/redeagle.js" /* webpackChunkName: "component---src-pages-slot-redeagle-js" */),
  "component---src-pages-slot-spinomenal-js": () => import("./../../../src/pages/slot/spinomenal.js" /* webpackChunkName: "component---src-pages-slot-spinomenal-js" */),
  "component---src-pages-slot-tom-horn-js": () => import("./../../../src/pages/slot/tom-horn.js" /* webpackChunkName: "component---src-pages-slot-tom-horn-js" */),
  "component---src-pages-sr-404-js": () => import("./../../../src/pages/sr/404.js" /* webpackChunkName: "component---src-pages-sr-404-js" */),
  "component---src-pages-sr-about-affiliates-js": () => import("./../../../src/pages/sr/about/affiliates.js" /* webpackChunkName: "component---src-pages-sr-about-affiliates-js" */),
  "component---src-pages-sr-about-company-js": () => import("./../../../src/pages/sr/about/company.js" /* webpackChunkName: "component---src-pages-sr-about-company-js" */),
  "component---src-pages-sr-about-fairness-and-rng-testing-methods-js": () => import("./../../../src/pages/sr/about/fairness-and-rng-testing-methods.js" /* webpackChunkName: "component---src-pages-sr-about-fairness-and-rng-testing-methods-js" */),
  "component---src-pages-sr-about-player-account-js": () => import("./../../../src/pages/sr/about/player-account.js" /* webpackChunkName: "component---src-pages-sr-about-player-account-js" */),
  "component---src-pages-sr-about-terms-and-conditions-js": () => import("./../../../src/pages/sr/about/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-sr-about-terms-and-conditions-js" */),
  "component---src-pages-sr-resources-aml-policy-js": () => import("./../../../src/pages/sr/resources/aml-policy.js" /* webpackChunkName: "component---src-pages-sr-resources-aml-policy-js" */),
  "component---src-pages-sr-resources-kyc-policy-js": () => import("./../../../src/pages/sr/resources/kyc-policy.js" /* webpackChunkName: "component---src-pages-sr-resources-kyc-policy-js" */),
  "component---src-pages-sr-resources-privacy-policy-js": () => import("./../../../src/pages/sr/resources/privacy-policy.js" /* webpackChunkName: "component---src-pages-sr-resources-privacy-policy-js" */),
  "component---src-pages-sr-resources-responsible-gaming-js": () => import("./../../../src/pages/sr/resources/responsible-gaming.js" /* webpackChunkName: "component---src-pages-sr-resources-responsible-gaming-js" */),
  "component---src-pages-sr-resources-self-exclusion-js": () => import("./../../../src/pages/sr/resources/self-exclusion.js" /* webpackChunkName: "component---src-pages-sr-resources-self-exclusion-js" */),
  "component---src-pages-sr-resources-support-and-complaints-js": () => import("./../../../src/pages/sr/resources/support-and-complaints.js" /* webpackChunkName: "component---src-pages-sr-resources-support-and-complaints-js" */),
  "component---src-pages-sv-404-js": () => import("./../../../src/pages/sv/404.js" /* webpackChunkName: "component---src-pages-sv-404-js" */),
  "component---src-pages-sv-about-affiliates-js": () => import("./../../../src/pages/sv/about/affiliates.js" /* webpackChunkName: "component---src-pages-sv-about-affiliates-js" */),
  "component---src-pages-sv-about-company-js": () => import("./../../../src/pages/sv/about/company.js" /* webpackChunkName: "component---src-pages-sv-about-company-js" */),
  "component---src-pages-sv-about-fairness-and-rng-testing-methods-js": () => import("./../../../src/pages/sv/about/fairness-and-rng-testing-methods.js" /* webpackChunkName: "component---src-pages-sv-about-fairness-and-rng-testing-methods-js" */),
  "component---src-pages-sv-about-player-account-js": () => import("./../../../src/pages/sv/about/player-account.js" /* webpackChunkName: "component---src-pages-sv-about-player-account-js" */),
  "component---src-pages-sv-about-terms-and-conditions-js": () => import("./../../../src/pages/sv/about/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-sv-about-terms-and-conditions-js" */),
  "component---src-pages-sv-resources-aml-policy-js": () => import("./../../../src/pages/sv/resources/aml-policy.js" /* webpackChunkName: "component---src-pages-sv-resources-aml-policy-js" */),
  "component---src-pages-sv-resources-kyc-policy-js": () => import("./../../../src/pages/sv/resources/kyc-policy.js" /* webpackChunkName: "component---src-pages-sv-resources-kyc-policy-js" */),
  "component---src-pages-sv-resources-privacy-policy-js": () => import("./../../../src/pages/sv/resources/privacy-policy.js" /* webpackChunkName: "component---src-pages-sv-resources-privacy-policy-js" */),
  "component---src-pages-sv-resources-responsible-gaming-js": () => import("./../../../src/pages/sv/resources/responsible-gaming.js" /* webpackChunkName: "component---src-pages-sv-resources-responsible-gaming-js" */),
  "component---src-pages-sv-resources-self-exclusion-js": () => import("./../../../src/pages/sv/resources/self-exclusion.js" /* webpackChunkName: "component---src-pages-sv-resources-self-exclusion-js" */),
  "component---src-pages-sv-resources-support-and-complaints-js": () => import("./../../../src/pages/sv/resources/support-and-complaints.js" /* webpackChunkName: "component---src-pages-sv-resources-support-and-complaints-js" */),
  "component---src-pages-table-games-404-js": () => import("./../../../src/pages/table-games/404.js" /* webpackChunkName: "component---src-pages-table-games-404-js" */),
  "component---src-pages-table-games-[gameprovidername]-js": () => import("./../../../src/pages/table-games/[gameprovidername].js" /* webpackChunkName: "component---src-pages-table-games-[gameprovidername]-js" */),
  "component---src-pages-table-games-aces-js": () => import("./../../../src/pages/table-games/aces.js" /* webpackChunkName: "component---src-pages-table-games-aces-js" */),
  "component---src-pages-table-games-all-games-js": () => import("./../../../src/pages/table-games/all-games.js" /* webpackChunkName: "component---src-pages-table-games-all-games-js" */),
  "component---src-pages-table-games-baccarat-js": () => import("./../../../src/pages/table-games/baccarat.js" /* webpackChunkName: "component---src-pages-table-games-baccarat-js" */),
  "component---src-pages-table-games-blackjack-american-blackjack-js": () => import("./../../../src/pages/table-games/blackjack/american-blackjack.js" /* webpackChunkName: "component---src-pages-table-games-blackjack-american-blackjack-js" */),
  "component---src-pages-table-games-blackjack-european-blackjack-js": () => import("./../../../src/pages/table-games/blackjack/european-blackjack.js" /* webpackChunkName: "component---src-pages-table-games-blackjack-european-blackjack-js" */),
  "component---src-pages-table-games-blackjack-js": () => import("./../../../src/pages/table-games/blackjack.js" /* webpackChunkName: "component---src-pages-table-games-blackjack-js" */),
  "component---src-pages-table-games-blackjack-single-deck-blackjack-js": () => import("./../../../src/pages/table-games/blackjack/single-deck-blackjack.js" /* webpackChunkName: "component---src-pages-table-games-blackjack-single-deck-blackjack-js" */),
  "component---src-pages-table-games-blackjack-vip-blackjack-js": () => import("./../../../src/pages/table-games/blackjack/vip-blackjack.js" /* webpackChunkName: "component---src-pages-table-games-blackjack-vip-blackjack-js" */),
  "component---src-pages-table-games-js": () => import("./../../../src/pages/table-games.js" /* webpackChunkName: "component---src-pages-table-games-js" */),
  "component---src-pages-table-games-poker-bonus-poker-js": () => import("./../../../src/pages/table-games/poker/bonus-poker.js" /* webpackChunkName: "component---src-pages-table-games-poker-bonus-poker-js" */),
  "component---src-pages-table-games-poker-joker-poker-js": () => import("./../../../src/pages/table-games/poker/joker-poker.js" /* webpackChunkName: "component---src-pages-table-games-poker-joker-poker-js" */),
  "component---src-pages-table-games-poker-js": () => import("./../../../src/pages/table-games/poker.js" /* webpackChunkName: "component---src-pages-table-games-poker-js" */),
  "component---src-pages-table-games-poker-oasis-poker-js": () => import("./../../../src/pages/table-games/poker/oasis-poker.js" /* webpackChunkName: "component---src-pages-table-games-poker-oasis-poker-js" */),
  "component---src-pages-table-games-poker-pai-gow-poker-js": () => import("./../../../src/pages/table-games/poker/pai-gow-poker.js" /* webpackChunkName: "component---src-pages-table-games-poker-pai-gow-poker-js" */),
  "component---src-pages-table-games-poker-video-poker-js": () => import("./../../../src/pages/table-games/poker/video-poker.js" /* webpackChunkName: "component---src-pages-table-games-poker-video-poker-js" */),
  "component---src-pages-table-games-roulette-american-roulette-js": () => import("./../../../src/pages/table-games/roulette/american-roulette.js" /* webpackChunkName: "component---src-pages-table-games-roulette-american-roulette-js" */),
  "component---src-pages-table-games-roulette-european-roulette-js": () => import("./../../../src/pages/table-games/roulette/european-roulette.js" /* webpackChunkName: "component---src-pages-table-games-roulette-european-roulette-js" */),
  "component---src-pages-table-games-roulette-js": () => import("./../../../src/pages/table-games/roulette.js" /* webpackChunkName: "component---src-pages-table-games-roulette-js" */),
  "component---src-pages-table-games-roulette-vip-roulette-js": () => import("./../../../src/pages/table-games/roulette/vip-roulette.js" /* webpackChunkName: "component---src-pages-table-games-roulette-vip-roulette-js" */),
  "component---src-pages-table-games-table-games-js": () => import("./../../../src/pages/table-games/table-games.js" /* webpackChunkName: "component---src-pages-table-games-table-games-js" */),
  "component---src-pages-test-vivo-game-integration-js": () => import("./../../../src/pages/test-vivo-game-integration.js" /* webpackChunkName: "component---src-pages-test-vivo-game-integration-js" */),
  "component---src-pages-unlock-account-js": () => import("./../../../src/pages/unlock-account.js" /* webpackChunkName: "component---src-pages-unlock-account-js" */),
  "component---src-pages-vip-js": () => import("./../../../src/pages/vip.js" /* webpackChunkName: "component---src-pages-vip-js" */)
}

