import {
    SET_TARGET_PAGE_AFTER_LOGIN,
    CLEAR_TARGET_PAGE_AFTER_LOGIN
} from "../../actions/targetPageAfterLogin/TargetPageAfterLoginActions"

const initialState = {
    targetPageAfterLogin: ""
}

let TargetPageAfterLoginReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_TARGET_PAGE_AFTER_LOGIN: 
            return {
                ...state, 
                targetPageAfterLogin: action.payload,
            };
        case CLEAR_TARGET_PAGE_AFTER_LOGIN:
            return {
                ...state,
                targetPageAfterLogin: "",
            }
        default: 
            return state;    
    }
};

export { TargetPageAfterLoginReducer };